export class JobExecutionListener {
    /*Called before a job executes*/
    beforeJob(jobExecution) {

    }

    /*Called after completion of a job. Called after both successful and failed executions*/
    afterJob(jobExecution) {

    }
}
