import {Node} from './node'

export class ChanceNode extends Node{

    static $TYPE = 'chance';

    constructor(location, milestone=undefined){
        super(ChanceNode.$TYPE, location, milestone);
    }
}
