export class StepExecutionListener {
    /*Called before a step executes*/
    beforeStep(jobExecution) {

    }

    /*Called after completion of a step. Called after both successful and failed executions*/
    afterStep(jobExecution) {

    }
}
